import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Base.css';
import { auth } from '../firebaseConfig'; // Assuming Firestore is initialized in firebaseConfig
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore'; // Import Firestore functions

// Define the type for a Subscription
interface Subscription {
  id: string;
  status: string;
  current_period_end: number;
  customer: string;
  collection_method: string;
  created: number;
  latest_invoice: string;
  currency: string;
  plan?: {
    id: string;
    nickname: string;
    amount: number;
  };
}

// Define the type for an Invoice
interface Invoice {
  id: string;
  hosted_invoice_url: string;
  invoice_pdf: string;
}

const AccountSubscription: React.FC<{ subscription: Subscription }> = ({ subscription }) => {
  const [invoice, setInvoice] = useState<Invoice | null>(null);

  // Fetch the latest invoice details from the API endpoint
  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await fetch(`/api/invoice?invoiceId=${subscription.latest_invoice}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch invoice: ${response.statusText}`);
        }
        const data: Invoice = await response.json();
        setInvoice(data);
      } catch (error: unknown) {
        console.error('Error fetching invoice:', error);
      }
    };

    if (subscription.latest_invoice) {
      fetchInvoice();
    }
  }, [subscription.latest_invoice]);

  return (
    <section className="subscription-container">
      <hr className="divider" />
      <p>Status: <span className="text-highlight">{subscription.status}</span></p>
      <p>Plan: <span>Premium</span> 
        - {subscription.plan?.amount ? `$${subscription.plan.amount / 100}` : 'N/A'} <span>/month</span> 
      </p>
      <p>Current Period End: <span className="text-highlight">{new Date(subscription.current_period_end * 1000).toLocaleDateString()}</span></p>

      {/* Display the invoice view/download options if available */}
      {invoice && (
        <>
          <p>
            <a href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer" className="invoice-link">
              View Invoice
            </a>
          </p>
        </>
      )}

      <Link to={'/billing-cancel'} state={{ subscription: subscription.id }} className="subscription-cancel-link">
        Cancel
      </Link>
    </section>
    
  );
};

const BillingAccount: React.FC = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');

  const handleViewHomePage = () => {
    navigate('/home');
  };

  useEffect(() => {
    const fetchFilesCount = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;
    };
    fetchFilesCount();
  }, []);

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadedBio: string) => {
    setUserImage(loadedImageUrl);
  };

  useEffect(() => {
    const fetchData = async () => {
      const firestore = getFirestore();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setError('User is not authenticated');
        return;
      }

      try {
        // Fetch the user's document from Firestore to get the customerId
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (!userDocSnapshot.exists()) {
          setError('User profile not found in Firestore');
          return;
        }

        const userData = userDocSnapshot.data();
        const customerId = userData?.customerId;

        if (!customerId) {
          setError('Customer ID not found in user profile');
          return;
        }

        // Fetch subscriptions using the customerId
        const response = await fetch(`/api/subscriptions?customerId=${customerId}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch subscriptions: ${response.statusText}`);
        }

        const data: Subscription[] = await response.json(); // Treat response as an array of Subscription objects
        setSubscriptions(data);

        // Check if any of the subscriptions are active or have a valid period
        const hasValidSubscription = data.some(subscription => 
          subscription.status === 'active' || 
          subscription.current_period_end * 1000 > Date.now() // Check if the current period end is after today's date
        );

        // Update the user's profile in Firestore with subscription status
        await updateDoc(userDocRef, {
          subscriptionStatus: hasValidSubscription
        });

      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!subscriptions.length) {
    return <p className="no-subscriptions">No subscriptions found.</p>;
  }

  return (
    <div className="container">
      <NavBar userImage={userImage} onProfileLoad={handleProfileLoad} />
      <div className="main-content">
        <div className="content">
          <div className="content-inner">
            <h1 className="billing-title">Your Subscription</h1>
                <div id="subscriptions">
                  {subscriptions.map((s) => (
                    <AccountSubscription key={s.id} subscription={s} />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <p onClick={handleViewHomePage} className="plan-home-page-link">
            Go to Home Page
          </p>
        </div>
  );
};

export default BillingAccount;
