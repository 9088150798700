import React, { useState, useEffect } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage'; // Ensure getDownloadURL is imported here
import { auth } from '../firebaseConfig';
import NavBar from './NavBar';
import Sidebar from './Sidebar'; 
import EditProfileModal from './EditProfileModal';
import './Base.css';
import { useNavigate } from 'react-router-dom';

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [bio, setBio] = useState(''); // Initialize bio state
  const [filesCount, setFilesCount] = useState(0); // Initialize filesCount state
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Disable scrolling when the component is mounted
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const fetchFilesCount = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      try {
        const storage = getStorage();
        const listRef = ref(storage, `uploads/`);
        const res = await listAll(listRef);

        // Filter files that belong to the current user
        const userFiles = res.items.filter((item) =>
          item.name.includes(currentUser.uid)
        );

        setFilesCount(userFiles.length); // Set filesCount to the number of user's files
      } catch (error) {
        console.error('Error fetching files count:', error);
      }
    };

    fetchFilesCount();
  }, []);

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadedBio: string) => { // Added bio
    setFirstName(loadedFirstName);
    setLastName(loadedLastName);
    setUserImage(loadedImageUrl);
    setBio(loadedBio); // Set bio
  };

  const handleEditProfile = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSaveProfile = async (updatedFirstName: string, updatedLastName: string, updatedBio: string, updatedImage: File | null) => {
    try {
      let imageUrl = userImage;

      if (updatedImage) {
        const imageRef = ref(getStorage(), `profileImages/${auth.currentUser?.uid}`);
        await uploadBytes(imageRef, updatedImage);
        imageUrl = await getDownloadURL(imageRef); // Correct usage of getDownloadURL
      }

      const userDocRef = doc(getFirestore(), 'users', auth.currentUser?.uid as string);
      await setDoc(userDocRef, {
        firstName: updatedFirstName,
        lastName: updatedLastName,
        bio: updatedBio,
        imageUrl: imageUrl,
      }, { merge: true });

      setFirstName(updatedFirstName);
      setLastName(updatedLastName);
      setBio(updatedBio); // Update bio
      setUserImage(imageUrl);

      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.href = '/';
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleHomeClick = async () => {
    // Your logic to handle the home click, e.g., navigate to the home page or fetch data
    navigate('/home'); // Example navigation action
  };

  return (
    <div className="container">
      <NavBar 
        userImage={userImage} 
        onProfileLoad={handleProfileLoad} 
      />
      <div className="main-content">
      <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />
        <div className="content">
          {/* Centered Profile Content */}
          <div className="content-inner">
            <div className="profile-header">
              <div className="profile-image-container">
                <img src={userImage} alt={`${firstName} ${lastName}`} className="profile-image" />
              </div>
              <div className="profile-info">
                <h1>{firstName} {lastName}</h1>
                <p>{bio || 'No bio yet.'}</p> {/* Display bio */}
                <button className="edit-button" onClick={handleEditProfile}>
                  Edit profile
                </button>
              </div>
            </div>

            {/* Stats */}
            <div className="profile-stats">
              <div>
                <strong>{filesCount}</strong> Files in Notes
              </div>

              <div>
                <strong>{filesCount}</strong> Q&A in Library
              </div>

            </div>

          </div>
        </div>
      </div>

      {isEditModalOpen && (
        <EditProfileModal
          firstName={firstName}
          lastName={lastName}
          userImage={userImage}
          userBio={bio} // Pass bio to modal
          onClose={handleCloseModal}
          onSave={handleSaveProfile}
        />
      )}
    </div>
  );
};

export default Profile;