import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import NavBar from './NavBar';
import './Base.css'; // New CSS file for specific styling
import { useNavigate } from 'react-router-dom';

const Plans: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');

  const handleViewHomePage = () => {
    navigate('/home');
  };

  const handleUpgradeClick = () => {
    navigate('/'); // Redirect to registration
  };

  useEffect(() => {
    const fetchFilesCount = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;
    };
    fetchFilesCount();
  }, []);

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadedBio: string) => {
    setUserImage(loadedImageUrl);
  };

  return (
    <div className="container">
      <NavBar 
        userImage={userImage} 
        onProfileLoad={handleProfileLoad} 
      />
      <div className="main-content">
        <div className="plans-content">
          <div className="content-inner">
            <h1 className="plan-title">My Plan 📋</h1>

            {/* Two-column table layout */}
            <table className="plan-table reduced-width-table"> {/* Add a specific class for reduced width */}
              <thead>
                <tr>
                  <th>Your Plan</th>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td colSpan={2}> {/* Use colspan to merge the next two cells */}
                    <button 
                      className="upgrade-button"
                      onClick={handleUpgradeClick}
                    >
                      Create an Account
                    </button>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th>Plan Type</th>
                  <td>Anonymous Sign-in</td>                 
                  <td>Starter Plan</td>
                  <td>Premium Plan</td>
                </tr>
                <tr>
                  <th>Subscription Fees</th>
                  <td>Free</td>                  
                  <td>Free</td>
                  <td>$4.99/month</td>
                </tr>
                <tr>
                  <th>Upload PDF files and texts</th>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                </tr>        
                <tr>
                  <th>Upload Images to Texts</th>
                  <td className="cross"  style={{ color: 'red' }} >x</td>                  
                  <td className="cross"  style={{ color: 'red' }} >x</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                </tr>      
                <tr>
                  <th>Share Files to Others</th>
                  <td className="cross"  style={{ color: 'red' }} >x</td>                  
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                </tr>                                            
                <tr>
                  <th>Number of Files in Notes</th>
                  <td>2</td>                  
                  <td>2</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <th>Number of Subjects in Library</th>
                  <td>2</td>                 
                  <td>2</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <th>Number of Questions per Document</th>
                  <td>10</td>                 
                  <td>10</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <th>Public Notes - under development</th>
                  <td className="cross"  style={{ color: 'red' }} >x</td>                 
                  <td className="cross"  style={{ color: 'red' }} >x</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                </tr>  
                <tr>
                  <th>Flash Card - under development</th>
                  <td className="cross"  style={{ color: 'red' }} >x</td>                 
                  <td className="cross"  style={{ color: 'red' }} >x</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                </tr>                   
                <tr>
                  <th>Chatbot Questions - under development</th>
                  <td className="cross"  style={{ color: 'red' }} >x</td>                 
                  <td className="cross"  style={{ color: 'red' }} >x</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                </tr>      
              </tbody>
            </table>
          </div>

          <p 
            onClick={handleViewHomePage} 
            className="plan-home-page-link"
          >
            Go to Home Page
          </p>
        </div>
      </div>
    </div>
  );
};

export default Plans;
