import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import NavBar from './NavBar';
import './Base.css'; // New CSS file for specific styling
import { useNavigate } from 'react-router-dom';

const MyPlan: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [isPremium, setIsPremium] = useState<boolean | null>(null); // Track user plan

  // Fetch the subscription status of the user from Firestore
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subscriptionStatus = userData?.subscriptionStatus;
        setIsPremium(subscriptionStatus === true); // Set whether user is premium or not
      }
    };

    fetchSubscriptionStatus();
  }, []);

  const handleViewHomePage = () => {
    navigate('/home');
  };

  const handleUpgradeClick = () => {
    navigate('/billing');
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadedBio: string) => {
    setUserImage(loadedImageUrl);
  };

  return (
    <div className="container">
      <NavBar 
        userImage={userImage} 
        onProfileLoad={handleProfileLoad} 
      />
      <div className="main-content">
        <div className="plans-content">
          <div className="content-inner">
            <h1 className="plan-title">My Plan 📋</h1>

            {/* Two-column table layout */}
            <table className="plan-table reduced-width-table"> {/* Add a specific class for reduced width */}
              <thead>
                <tr>
                  <th>Your Plan</th>
                  {isPremium === false ? (
                    <>
                      <td className="checkmark" style={{ color: 'green' }}>✔</td>
                      <td>
                        <button
                          className="upgrade-button"
                          onClick={handleUpgradeClick}
                        >
                          Upgrade
                        </button>
                      </td>
                    </>
                  ) : isPremium === true ? (
                    <>
                      <td></td>
                      <td className="checkmark" style={{ color: 'green' }}>✔</td>
                    </>
                  ) : (
                    <td colSpan={2}>Loading...</td>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Plan Type</th>
                  <td>Starter Plan</td>
                  <td>Premium Plan</td>
                </tr>
                <tr>
                  <th>Subscription Fees</th>
                  <td>Free</td>
                  <td>$4.99/month</td>
                </tr>
                <tr>
                  <th>Upload PDF Files and Texts</th>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                </tr>
                <tr>
                  <th>Upload Images to Texts</th>
                  <td className="cross" style={{ color: 'red' }}>x</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                </tr>
                <tr>
                  <th>Share Files to Others</th>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                </tr>
                <tr>
                  <th>Number of Files in Notes</th>
                  <td>2</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <th>Number of Subjects in Library</th>
                  <td>2</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <th>Number of Questions per Document</th>
                  <td>10</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <th>Public Notes - under development</th>
                  <td className="cross" style={{ color: 'red' }}>x</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                </tr>
                <tr>
                  <th>Flash Card - under development</th>
                  <td className="cross" style={{ color: 'red' }}>x</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                </tr>
                <tr>
                  <th>Chatbot Questions - under development</th>
                  <td className="cross" style={{ color: 'red' }}>x</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p
            onClick={handleViewHomePage}
            className="plan-home-page-link"
          >
            Go to Home Page
          </p>
        </div>
      </div>
    </div>
  );
};

export default MyPlan;
